import { useCadastro } from "../../hooks/useCadastro";
import styles from "./styles.module.css";

export function Checkbox() {
  const { addValorDoFormulario } = useCadastro();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <label htmlFor="funcionarios">Número de empregados</label>
        <div className={styles.help}>
          ?{" "}
          <span className={styles.helpMessage}>
            Informe a quantidade colaboradores na empresa
          </span>
        </div>
      </div>
      <div className={styles.checkGroup}>
        <label className={styles.label}>
          <input
            type="radio"
            name="funcionarios"
            value="ate20"
            onClick={(e) => addValorDoFormulario(e.target)}
          />
          <span>0 &lt; 20</span>
        </label>
        <label className={styles.label}>
          <input
            type="radio"
            name="funcionarios"
            value="ate50"
            onClick={(e) => addValorDoFormulario(e.target)}
          />
          <span>20 &lt; 50</span>
        </label>
        <label className={styles.label}>
          <input
            type="radio"
            name="funcionarios"
            value="ate100"
            onClick={(e) => addValorDoFormulario(e.target)}
          />
          <span>50 &lt; 100</span>
        </label>
        <label className={styles.label}>
          <input
            type="radio"
            name="funcionarios"
            value="acima100"
            onClick={(e) => addValorDoFormulario(e.target)}
          />
          <span>100 &lt; ...</span>
        </label>
      </div>
    </div>
  );
}
