import { Cadastro } from "./pages/cadastro";
import { CadastroProvider } from "./hooks/useCadastro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <CadastroProvider>
      <ToastContainer theme="colored" position="bottom-right" />
      <Cadastro />
    </CadastroProvider>
  );
}

export default App;
