import { Checkbox } from "../../components/checkbox";
import { Pergunta } from "../../components/pergunta";
import { SelectFilial } from "../../components/selectFilial";
import { Select } from "../../components/select";
import { useCadastro } from "../../hooks/useCadastro";

import styles from "./styles.module.css";

import Logo from "../../assets/logo_acal.svg";
import Escada from "../../assets/ESCADA.svg";
import Janela from "../../assets/JANELA.svg";
import Construcao from "../../assets/CONSTRUÇÃO.svg";
import Acessorio from "../../assets/ACESSÓRIO.svg";
import Banheiro from "../../assets/BANHEIRO.svg";
import Ferramenta from "../../assets/FERRAMENTA.svg";

export function Cadastro() {
  const { addValorDoFormulario, cadastrarEmpresa, loader } = useCadastro();

  const tiposDeEmpresa = [
    { value: "", text: "--SELECIONE UMA OPÇÃO--" },
    { value: "MEI", text: "MEI" },
    { value: "EIRELI", text: "EIRELI" },
    { value: "MICRO", text: "MICRO" },
    { value: "LTDA", text: "LTDA" },
    { value: "SA", text: "S/A" },
  ];

  const porteEmpresa = [
    { value: "", text: "--SELECIONE UMA OPÇÃO--" },
    { value: "MEI", text: "MEI/EIRELI" },
    { value: "MICRO", text: "MICRO EMPRESA" },
    { value: "PEQUENA", text: "PEQUENA EMPRESA" },
    { value: "MEDIA", text: "MEDIA EMPRESA" },
    { value: "GRANDE", text: "GRANDE EMPRESA" },
    { value: "RESIDENCIAL", text: "CONDOMÍNIO RESIDENCIAL" },
    { value: "COMERCIALSMALL", text: "CONDOMÍNIO COMERCIAL SMALL" },
    { value: "CONDOMINIOENTERPRISE", text: "CONDOMÍNIO COMERCIAL ENTERPRISE" },
  ];

  const perfisDeEmpresas = [
    { value: "", text: "--SELECIONE UMA OPÇÃO--" },
    { value: "comercio", text: "COMÉRCIO/ATACADO/SERVIÇO" },
    { value: "condominio", text: "CONDOMÍNIO" },
    { value: "construtora", text: "CONSTRUTORA" },
  ];

  return (
    <>
      <main className={styles.container}>
        <section className={styles.section}>
          <header className={styles.header}>
            <h1>Cadastre sua empresa</h1>
            <p>
              Somos a melhor empresa quando o assunto é reforma e construção
              cadastre-se conosco e tenha acesso as melhores condições de
              pagamento. Nós seguimos todas as politícas de proteção de dados
              confira em <span>Termos e Condições</span> e{" "}
              <span>Politicas de privacidade</span>.
            </p>
          </header>
          <form className={styles.form}>
            <div className={styles.row}>
              <Pergunta
                name="nome"
                label="Nome"
                info="Informe o nome"
                placeholder="Fulano de Tal"
              />
              <Pergunta
                name="razao_social"
                label="Razão Social"
                info="Informe a razão social da empresa"
                placeholder="Minha empresa LTDA"
              />
            </div>
            <div className={styles.row}>
              <Pergunta
                name="cnpj"
                label="Cnpj"
                info="Informe o número do cnpj da empresa"
                placeholder="99.999.999/9999-99"
                maxLength={14}
              />
              <Pergunta
                name="fantasia"
                label="Fantasia"
                info="Informe o nome fantasia da empresa"
                placeholder="Minha empresa"
              />
            </div>
            <div className={styles.row}>
              <Select
                name="tipoEmpresa"
                label="Tipo de Empresa"
                info="Informe o tipo de empresa."
                options={tiposDeEmpresa}
              />
              <Select
                name="perfilEmpresa"
                label="Perfil da empresa"
                info="Informe o perfil da empresa."
                options={perfisDeEmpresas}
              />
            </div>
            <div className={styles.row}>
              <Select
                name="porteEmpresa"
                label="Porte da empresa"
                info="Informe o porte da empresa."
                options={porteEmpresa}
              />
              <Checkbox />
            </div>
            <div className={styles.row}>
              <Pergunta
                name="cidade"
                label="Cidade"
                info="Informe a cidade da sua empresa"
                placeholder="Fortaleza"
              />
              <Pergunta
                name="uf"
                label="UF"
                info="Informe a UF da sua empresa"
                placeholder="CE"
              />
            </div>
            <div className={styles.row}>
              <Pergunta
                name="email"
                label="E-mail"
                info="Informe o e-mail da empresa"
                placeholder="minhaempresa@minhaempresa.com"
              />
              <Pergunta
                name="telefone"
                label="Telefone"
                info="Informe o telefone com DDD da empresa"
                placeholder="(99) 99999-9999"
                maxLength={11}
              />
            </div>
            <SelectFilial />
            <div className={styles.confirmationArea}>
              <label className={styles.checkbox}>
                <p>Eu concordo com os termos e condições</p>
                <input
                  type="checkbox"
                  name="termos"
                  onChange={(element) => {
                    addValorDoFormulario(element.target);
                  }}
                />
                <span className={styles.checkmark}></span>
              </label>
              <button type="button" onClick={() => cadastrarEmpresa()}>
                Cadastrar agora
              </button>
            </div>
          </form>
        </section>
        <aside className={styles.aside}>
          <img src={Logo} alt="Acal Home Center" />
          <div className={styles.icons}>
            <img src={Escada} alt="Icon de escada" />
            <img src={Janela} alt="Icon de janela" />
            <img src={Construcao} alt="Icon de construção" />
            <img src={Ferramenta} alt="Icon de ferramenta" />
            <img src={Banheiro} alt="Icon de banheiro" />
            <img src={Acessorio} alt="Icon de acessórios" />
          </div>
        </aside>
      </main>
      {loader}
    </>
  );
}
