import Spinner from "../../assets/spinner.gif";

import styles from "./styles.module.css";

export function LoadingSpinner() {
  return (
    <div className={styles.container}>
      <img src={Spinner} alt="loading" className={styles.spinner} />
    </div>
  );
}
