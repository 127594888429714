import { useCadastro } from "../../hooks/useCadastro";
import styles from "./styles.module.css";

export function Select(props) {
  const { addValorDoFormulario } = useCadastro();

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <label htmlFor={props.name}>{props.label}</label>
        <div className={styles.help}>
          ? <span className={styles.helpMessage}>{props.info}</span>
        </div>
      </div>
      <select
        className={styles.input}
        name={props.name}
        onBlur={(element) => {
          addValorDoFormulario(element.target);
        }}
      >
        {props.options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
    </div>
  );
}
