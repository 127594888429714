import { createContext, useContext, useState, useCallback } from "react";
import { toast } from "react-toastify";
import useLoading from "./useLoading";

const CadastroContext = createContext({});

export function CadastroProvider({ children }) {
  const [nome, setNome] = useState("");
  const [razao, setRazao] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [fantasia, setFantasia] = useState("");
  const [site, setSite] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [qtdeEmpregados, setQtdeEmpregados] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [tipoEmpresa, setTipoEmpresa] = useState("");
  const [perfilEmpresa, setPerfilEmpresa] = useState("");
  const [porteEmpresa, setPorteEmpresa] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [filial, setFilial] = useState("");
  const [foiAtendido, setFoiAtendido] = useState("");
  const [termos, setTermos] = useState(false);

  const [loader, showLoader, hideLoader] = useLoading();

  const isAgree = termos === true;

  //VALIDACOES E INSERÇÕES DE ITENS DO FORMULARIO
  const addValorDoFormulario = (element) => {
    if (element.name === "nome") {
      setNome(element.value);
    } else if (element.name === "razao_social") {
      setRazao(element.value);
    } else if (element.name === "cnpj") {
      mascaraCnpj(element);
      validarCnpj(element);
    } else if (element.name === "fantasia") {
      setFantasia(element.value);
    } else if (element.name === "cidade") {
      mascaraLetras(element);
      setCidade(element.value);
    } else if (element.name === "tipoEmpresa") {
      validarSelect(element);
      setTipoEmpresa(element.value);
    } else if (element.name === "perfilEmpresa") {
      validarSelect(element);
      setPerfilEmpresa(element.value);
    } else if (element.name === "porteEmpresa") {
      validarSelect(element);
      setPorteEmpresa(element.value);
    } else if (element.name === "uf") {
      mascaraLetras(element);
      setUf(element.value);
    } else if (element.name === "funcionarios") {
      setQtdeEmpregados(element.value);
    } else if (element.name === "telefone") {
      mascaraTelefone(element);
      setTelefone(element.value);
      validarTelefone(element);
    } else if (element.name === "email") {
      setEmail(element.value);
      validarEmail(element);
    } else if (element.name === "foiAtendido") {
      setFoiAtendido(element.value);
    } else if (element.name === "filial") {
      validarSelect(element);
      setFilial(element.value);
    } else if (element.checked === true) {
      setTermos(true);
    } else if (element.checked === false) {
      setTermos(false);
    }
  };

  // VALIDACOES DE FORMULARIO
  const validarTelefone = useCallback((element) => {
    let value = element.value;
    let telefoneValido = /\d{4,5}-\d{4}/g.test(value);
    if (telefoneValido) {
      element.style.color = "black";
      element.style.borderColor = "#333";
    } else {
      element.style.borderColor = "red";
      element.style.color = "red";
      setTelefone("");
    }
    return telefoneValido;
  }, []);

  const validarCnpj = useCallback((element) => {
    let value = element.value;
    let cnpjValido = /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/g.test(value);
    let valueFormatado = value.replace(/\D/g, "");
    if (cnpjValido) {
      element.style.color = "black";
      element.style.borderColor = "#333";
      setCnpj(valueFormatado);
    } else {
      element.style.borderColor = "red";
      element.style.color = "red";
      setCnpj("");
    }
    console.log(valueFormatado);
    return valueFormatado;
  }, []);

  const validarCep = useCallback((element) => {
    let value = element.value;
    let cepValido = /\d{5}-\d{3}/g.test(value);
    if (cepValido) {
      element.style.color = "black";
      element.style.borderColor = "#333";
    } else {
      element.style.borderColor = "red";
      element.style.color = "red";
      setCep("");
    }
    return cepValido;
  }, []);

  const validarEmail = (element) => {
    const re = /\S+@\S+\.\S+/;
    const emmailValido = re.test(element.value);
    if (emmailValido) {
      element.style.color = "black";
      element.style.borderColor = "#333";
    } else {
      element.style.borderColor = "red";
      element.style.color = "red";
      setEmail("");
    }
    return emmailValido;
  };

  const validarSelect = useCallback((element) => {
    let value = element.value;
    let optionValida = value !== "";
    if (optionValida) {
      element.style.color = "black";
      element.style.borderColor = "#333";
    } else {
      element.style.borderColor = "red";
      element.style.color = "red";
      setFilial("");
    }
    return optionValida;
  }, []);

  // MASCARAS PARA INPUTS
  const mascaraCnpj = useCallback((element) => {
    let value = element.value;
    value = value.replace(/\D/g, "");
    value = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    element.value = value;
  }, []);

  const mascaraTelefone = useCallback((element) => {
    let value = element.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d{4,5})(\d{4})/, "($1)$2-$3");
    element.value = value;
  }, []);

  const mascaraCep = useCallback((element) => {
    let value = element.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");
    element.value = value;
  }, []);

  const mascaraLetras = useCallback((element) => {
    let value = element.value;
    value = value.replace(/\d/g, "");
    element.value = value;
  }, []);

  const mascaraNumeros = useCallback((element) => {
    let value = element.value;
    value = value.replace(/\D/g, "");
    element.value = value;
  }, []);

  // ENVIAR DADOS PREENCHIDOS PARA O BACKEND
  const enviarDadosDaEmpresa = async (dados) => {
    const resposta = await fetch(
      "https://acalcorporativo.azurewebsites.net/empresas",
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(dados, null, 2),
      }
    ).then((res) => {
      hideLoader();
      return res;
    });

    // const reload = () => setTimeout(window.location.reload(), 8000);
    // RETORNO DA PROMISE PARA O USUÁRIO
    if (resposta.status === 201) {
      toast.success("Cadastro realizado com sucesso.");
      // reload();
      return true;
    }
    if (resposta.status === 409) toast.error("Cnpj ou E-mail já cadastrado!");
    if (resposta.status === 503)
      toast.error("Problemas ao realizar cadastro, tente novamente mais tarde");
  };

  // REALIZAR CADASTRO DA EMPRESA
  const cadastrarEmpresa = () => {
    const novaEmpresa = {
      nome,
      razao_social: razao,
      cnpj,
      fantasia,
      cidade,
      uf,
      telefone,
      email,
      numero_empregados: qtdeEmpregados,
      filial_atendimento: filial,
      porte_empresa: porteEmpresa,
      tipo_empresa: tipoEmpresa,
      perfil_empresa: perfilEmpresa,
      status: "pendente",
    };

    console.log(novaEmpresa);

    if (foiAtendido === "" || foiAtendido === "nao" || filial === "")
      delete novaEmpresa.filial_atendimento;

    const arrayNovaEmpresa = Object.entries(novaEmpresa);

    const existeCampoVazio = arrayNovaEmpresa.filter(
      (el) => el[1] === "" && el[0] !== "site"
    );

    if (isAgree) {
      if (existeCampoVazio.length > 0) {
        toast.warning("Existem campos vazios");
      } else {
        enviarDadosDaEmpresa(novaEmpresa);
        showLoader();
      }
    } else {
      toast.error("Aceite os termos para prosseguir");
    }
  };

  return (
    <CadastroContext.Provider
      value={{
        addValorDoFormulario,
        cadastrarEmpresa,
        loader,
      }}
    >
      {children}
    </CadastroContext.Provider>
  );
}

export function useCadastro() {
  const context = useContext(CadastroContext);

  return context;
}
