import { useRef } from "react";
import { useCadastro } from "../../hooks/useCadastro";
import styles from "./styles.module.css";

export function SelectFilial() {
  const { addValorDoFormulario } = useCadastro();
  const refSelect = useRef(null);

  const handleFoiAtendido = (element) => {
    if (element === "sim") refSelect.current.style.display = "flex";
    if (element === "nao") refSelect.current.style.display = "none";
  };

  return (
    <div className={styles.row}>
      <div className={styles.container}>
        <div className={styles.label}>
          <label htmlFor="foiAtendido">Foi atendido em alguma filial?</label>
          <div className={styles.help}>
            ?{" "}
            <span className={styles.helpMessage}>
              Informe se você foi atendido em uma de nossas filiais.
            </span>
          </div>
        </div>
        <select
          className={styles.input}
          name="foiAtendido"
          onChange={(element) => handleFoiAtendido(element.target.value)}
          onBlur={(element) => {
            addValorDoFormulario(element.target);
          }}
        >
          <option value="nao">Não</option>
          <option value="sim">Sim</option>
        </select>
      </div>
      <div className={styles.containerFiliais} ref={refSelect}>
        <div className={styles.label}>
          <label htmlFor="filial">Filial de atendimento</label>
          <div className={styles.help}>
            ?{" "}
            <span className={styles.helpMessage}>
              Informe a filial onde você foi atendido.
            </span>
          </div>
        </div>
        <select
          className={styles.input}
          name="filial"
          onChange={(element) => addValorDoFormulario(element.target)}
        >
          <option value="">--Selecione uma filial--</option>
          <option value="tg">Tristão Gonçalves</option>
          <option value="as">Antônio Sales</option>
          <option value="ws">Washington Soares</option>
          <option value="gm">Parangaba</option>
          <option value="ce">Caucaia</option>
          <option value="dm">Desembargador Moreira</option>
        </select>
      </div>
    </div>
  );
}
