import { useCadastro } from "../../hooks/useCadastro";
import styles from "./styles.module.css";

export function Pergunta(props) {
  const { addValorDoFormulario } = useCadastro();

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <label htmlFor={props.name}>{props.label}</label>
        <div className={styles.help}>
          ? <span className={styles.helpMessage}>{props.info}</span>
        </div>
      </div>
      <input
        type={props.type}
        name={props.name}
        id={props.name}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        className={styles.input}
        onBlur={(element) => {
          addValorDoFormulario(element.target);
        }}
      />
    </div>
  );
}
