import { useState } from "react";
import { LoadingSpinner } from "../components/loadingSpinner";

const useLoading = () => {
  const [loading, setLoading] = useState(false);
  return [
    loading ? <LoadingSpinner /> : null,
    () => setLoading(true), // Show loader
    () => setLoading(false), // Hide loader
  ];
};

export default useLoading;
